import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Table, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../service/api.service";
import swal from "sweetalert";
import { folioContractAddress, formatWalletAddress, folioDecimals, nftMinterAddress, showCheckout, adConfig , nftAllContract , discountContract } from "../config/config";
import Web3 from 'web3';
import NftReddemabi from '../common/NftReddemabi.json'
import { tokenAbi } from "../config/tokenAbi";
import { formatBalanceWithDecimals } from "../common/connectWallet";
import Pagination from "react-js-pagination";
import { adClient } from '../common/adConfig'
import { useSelector, useDispatch } from "react-redux";
import { ethers } from "ethers";
import Countdown from "react-countdown";
import moment from "moment";
import AdComponent from "../common/AdComponent";
import { FundAddModal } from "../common/FundAddModal";
import { discountAbi } from "../config/discountAbi";
// 1 solo -- 100 folio
// 2 extended -- 300 
// 3 multichoice -- 100



const Redem = () => {
    const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
    const [balance, setBalance] = useState(0);
    const [activePercentage, setActivePercentage] = useState(10);
    const [inputValue, setInputValue] = useState("");
    const [loader, setLoader] = useState(false);
    const [folioValue, setFolioValue] = useState("");
    const [redmeeList, setRedmeeList] = useState([]);
    const [filter, setFilter] = useState("All");
    const [folioBalanceWei, setFolioBalanceWei] = useState(0)
    const [folioAllowance, setFolioAllowance] = useState(0)
    const [showStepsModal, setShowStepsModal] = useState(false)
    const [tokenApproved, setTokenApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nftMint, setNftMint] = useState()
    const [userRedemptions, setUserRedemptions] = useState()
    const [totalFolioRedeemed, setTotalFolioRedeemed] = useState(0)
    const [refreshHook, setRefreshHook] = useState(); // update useeffect
    const [totalCount, settotalCount] = useState()
    const [page, setpage] = useState(0)
    const walletBalance = useSelector((state) => state.reducer.walletBalance)
    const [warningModalshow, setWarningModalshow] = useState(false);
    const [selectedNftType, setSelectedNftType] = useState(null);


    const handleWarningModalClose = () => setWarningModalshow(false);
    
    const USER_MONTHLY_REEDEM_LIMIT = 2000;
    const MONTHLY_PLATFORM_REEDEM_LIMIT = 100000000;
    const BASE_GAS_MULTIPLIER = 2; // Multiplier to add a buffer to estimated gas

    const percentages = [10, 20, 50, 100];
    const isConnected = !!localStorage.getItem("connected_wallet");
    const handleCloseStepsModal = () => setShowStepsModal(false);
    const userAddress = localStorage.getItem("connected_wallet");
    // const userAddress = useSelector((state) => state.reducer.walletAddress);
    const NFT_NAME = ["Solo", "Extended solo", "Multi Choice"]
    // console.log("==========================web3AuthProvider=====",web3AuthProvider)
    let web3 = new Web3(window.ethereum);
    if (localStorage.getItem("user_type") == "CUSTODIAL" && web3AuthProvider) {
        web3 = new Web3(web3AuthProvider);
        // console.log("==============CUSTODIAL============web3AuthProvider=====",web3AuthProvider)
        // setProvider(web3AuthProvider)
    }
    useEffect(() => {
        const requiredFolio = nftMint === 1 ? 100 : nftMint === 3 ? 100 : 300;
        setTokenApproved(folioAllowance >= web3.utils.toWei(requiredFolio.toString(), "ether"));
    }, [folioAllowance, nftMint]);



    useEffect(() => {
        // Calculate the default values based on the default percentage
        const defaultPercentage = 10;
        const calculatedValue = (balance * defaultPercentage) / 100;
        setActivePercentage(defaultPercentage);
        setInputValue(parseInt(calculatedValue));
        setFolioValue(parseInt(calculatedValue));
    }, [balance]);

    useEffect(() => {
        console.log("refreshHook");
        get_userPoints();
        getBonusPoint();
        get_RedmeeList(filter, page);
        getFolioBalance();

    }, [refreshHook, web3AuthProvider]);
    // useEffect(() => {

    //     getFolioBalance()
    // }, []);
    function handlePageChange(pageNumber) {
        console.log("??????page", pageNumber)
        if (page != pageNumber - 1) {
            setpage(pageNumber - 1);
            get_RedmeeList(filter, (pageNumber - 1));
        }

    }



    const handlePercentageClick = (percentage) => {
        const calculatedValue = (balance * percentage) / 100;
        setActivePercentage(percentage);
        setInputValue(parseInt(calculatedValue));
        setFolioValue(parseInt(calculatedValue));
    };

    // const handleInputChange = (e) => {
    //     const value = e.target.value;
    //     setInputValue(value);
    //     setFolioValue(value); // 1 point equals 1 folio
    // };
    const handleInputChange = (e) => {
        let value = e.target.value;

        // Prevent leading space
        if (value.length === 1 && value === " ") {
            value = ""; // Ignore the input if it's a leading space
        }

        // Allow only numbers and spaces (but no leading spaces)
        value = value.replace(/[^0-9 ]/g, "");

        setInputValue(value);
        setFolioValue(value.trim()); // 1 point equals 1 folio, trim any trailing spaces
    };



    const PointsProgram = [
        { value: "All", label: "All" },
        { value: "Own", label: "My Account" },
    ];





    /* convert points to folio */
    const handleConvertRedeem = async () => {
        console.log("handleConvertRedeem");
        const params = {
            points: inputValue,
        }
        try {
            setLoader(true);
            const response = await apiService.convertRedeem(params);
            if (response.status == 200) {
                swal({
                    title: 'Success!',
                    text: 'FOLIO request sent successfully. It may take some time to appear on the listing.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#3085d6',
                    backdrop: true,
                    position: 'center',
                    timer: 3000, // Optional: auto-close after 3 seconds
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Handle what happens after the user clicks 'OK'
                    }
                });
                get_RedmeeList(filter, page); // change here
                console.log(response.data);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                localStorage.clear();
                window.location.href = "/";
            } else {
                // Handle other errors
                console.log("error>>>>>", error);
                swal({ icon: "error", text: error?.response?.data?.error, button: "OK" })


            }
        }
    };

// --------------- get code start --------------



const getRequiredFolio = (nft_type) => {
    const folioRequirements = {
        9: 750,
        10: 2500,
        11: 750,
        12: 5000
    };
    return folioRequirements[nft_type] || 10000; // Default to 10000 if nft_type is not listed
};


const handleProceedAnyway = () => {
    if (selectedNftType !== null) {
        redeemFolioAction(selectedNftType);
        setWarningModalshow(false); // Close modal
    }
};

const redeemFolio = async (nft_type) => {
    let discountContractWeb3 = new web3.eth.Contract(discountAbi, discountContract);
    const getAvailableNft = await discountContractWeb3.methods.getDiscount(userAddress).call();
        if (getAvailableNft === "0x0000000000000000000000000000000000000000" || getAvailableNft==0) {
            console.log("No NFT available (zero address)");
            redeemFolioAction(nft_type);
        } else {
            console.log("NFT available:", getAvailableNft);
            setSelectedNftType(nft_type); // Store selected NFT type
            setWarningModalshow(true);
            return;
        }
}

const redeemFolioAction = async (nft_type) => {
    // const requiredFolio = nft_type === 9 ? 50000 : 10000;
    


    const requiredFolio = getRequiredFolio(nft_type);

    console.log("requiredFolio>>>>>>>>>",  requiredFolio);
    setTokenApproved(false)
    setShowStepsModal(true);
    setIsLoading(true); // Set the loader for the modal
    setNftMint(nft_type);
    try {
        let web3 = new Web3(web3AuthProvider);
        let folioContract = new web3.eth.Contract(tokenAbi, folioContractAddress);
        // Check allowance
        console.log("allowance>>>>>", folioContract);
        const allowance = await folioContract.methods.allowance(userAddress, nftAllContract).call();
        console.log("allowance>>>>>", allowance);
        setFolioAllowance(parseInt(allowance));

        if (parseInt(allowance) >= web3.utils.toWei(requiredFolio.toString(), "ether")) {
            // If token is already approved, proceed to mint the NFT
            console.log("allowance>>>>>===aaa========", parseInt(allowance));
            setTokenApproved(true);
            await mintNFT(nft_type);
        } else {
                // If not approved, approve the tokens
                console.log("approveFolio>>>>>=====parseInt(allowance)======", parseInt(allowance));
                const isApproved = await approveFolio(requiredFolio, nft_type);
                if (isApproved) {
                    setTokenApproved(true);
                    await mintNFT(nft_type);
                }

            }
        } catch (error) {
            console.log("Error in redeeming FOLIO:", error);
            swal("Error", error.message, "error");
        }
        // finally {
        //     setIsLoading(false);
        //     setShowStepsModal(false); // Close the modal
        // }
    };


    const approveFolio = async (amount, nft_type) => {
        setTokenApproved(false);
        const weiAmount = web3.utils.toWei(amount, "ether");
        let sendParams = { from: userAddress };

        try {
            let web3 = new Web3(web3AuthProvider);
            let folioContract, balacneInETH = 0;

            if (localStorage.getItem("user_type") === "CUSTODIAL" && web3AuthProvider) {
                const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
                const balance = await ethersProvider.getBalance(userAddress);
                balacneInETH = ethers.utils.formatEther(balance);
                console.log(`ETH Balance: ${ethers.utils.formatEther(balance)} ETH`);
                const signer = ethersProvider.getSigner();

                folioContract = new ethers.Contract(folioContractAddress, tokenAbi, signer);
            } else {
                folioContract = new web3.eth.Contract(tokenAbi, folioContractAddress);
            }
            console.log("folioContract======", folioContract);

        if (localStorage.getItem("user_type") === "CUSTODIAL") {
            if (Number(balacneInETH) < 0.1) {

                await swal({
                    content: {
                      element: "div",
                      attributes: {
                        innerHTML: `
                          To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
                          <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
                          <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
                          <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
                        `
                      }
                    },
                    icon: "info",
                  });
                setShowStepsModal(false);
                setIsLoading(false); // Set the loader for the modal
                return false;
            }

            return await swal({
                text: "Are you sure you want to complete this transaction?",
                icon: "info",
                buttons: ["Cancel", "Approve tokens"]
            }).then(async (res) => {
                   if (res) {
                        const tx = await folioContract.approve(nftAllContract, weiAmount);
                        console.log("Transaction Hash:======", tx.hash);
                        const receipt = await tx.wait();
                        setTokenApproved(true);
                        console.log("Transaction successful, receipt:", receipt);
                        return true;
                    } else {
                        setTokenApproved(false);
                        setShowStepsModal(false);
                        setIsLoading(false); // Set the loader for the modal
                        // swal("Error", "User denied transaction.", "error");
                        return false;
                    }
                })
            } else {
                const gasPrice = await web3.eth.getGasPrice();
                sendParams.gasPrice = Math.ceil(Number(gasPrice) * BASE_GAS_MULTIPLIER);

                try {
                    const estimatedGas = await folioContract.methods.approve(nftAllContract, weiAmount).estimateGas(sendParams);
                    sendParams.gas = Math.ceil(Number(estimatedGas) * BASE_GAS_MULTIPLIER);
            } catch (gasError) {
                sendParams.gas = 500000; // Fallback gas limit
            }

            const receipt = await folioContract.methods.approve(nftAllContract, weiAmount).send(sendParams);
            console.log('Transaction successful with hash:', receipt.transactionHash);
            return true;
        }
    } catch (error) {
        console.log('Transaction failed:', error, error.data);
        if (error.data) {
            console.error("Error data:", error.data);
        }
        setShowStepsModal(false);
        setIsLoading(false); // Set the loader for the modal
        let err = error.toString();
        if (error.code == 4001) {
            swal("Error", "User denied transaction.", "error");
        } else if (err.includes("User denied transaction")) {
            swal("Error", "User denied transaction.", "error");
             } else if (err.includes("user rejected")) {
            swal("Error", "User denied transaction.", "error");
        }
        return false;
    }
};

const mintNFT = async (nft_type) => {
        try {
            // Initialize the contract
            let nftMinterContract, balacneInETH = 0;
            web3 = new Web3(window.ethereum);
            if (localStorage.getItem("user_type") == "CUSTODIAL" && web3AuthProvider) {
                web3 = new Web3(web3AuthProvider);
                const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
                const balance = await ethersProvider.getBalance(userAddress);
                balacneInETH = ethers.utils.formatEther(balance);
                console.log("====ethersProvider=======", ethersProvider)

                const signer = ethersProvider.getSigner();

                nftMinterContract = new ethers.Contract(
                    nftAllContract,
                    NftReddemabi,
                    signer
                );
            } else {
                nftMinterContract = new web3.eth.Contract(NftReddemabi, nftAllContract);


            }
            // Set up transaction parameters
            const sendParams = { from: userAddress };

            // Fetch current gas price
            const gasPrice = await web3.eth.getGasPrice();
            sendParams.gasPrice = Math.ceil(Number(gasPrice) * BASE_GAS_MULTIPLIER);
            // Estimate gas required for mintNFT
            if (localStorage.getItem("user_type") == "CUSTODIAL") {
                if (Number(balacneInETH) < 0.1) {
                    // await swal({
                    //     text: "Insufficient POL balance.",
                    //     icon: "info",
                    //     buttons: ["Close", "Add Funds"]
                    //   }).then(res => {
                    //     if (res) {
                    //       showCheckout();
                    //     }
                    //   });
                    // await swal({
                    //         content: {
                    //           element: "div",
                    //           attributes: {
                    //             innerHTML: `
                    //               To buy POL or USDC with a credit card or Paypal and deposit to your BetFolio wallet address, use any of the following services:
                    //               <a href="https://changelly.com/buy/pol" target="_blank">Changelly</a>,
                    //               <a href="https://paybis.com/buy-polygon/" target="_blank">Paybis</a>,
                    //               <a href="https://global.transak.com/wallet-confirm" target="_blank">Transak</a>
                    //             `
                    //           }
                    //         },
                    //         icon: "info",
                    //       });
                    await FundAddModal();
                        setShowStepsModal(false);
                        setIsLoading(false); // Set the loader for the modal
                        return false;
                    }
                    await swal({
                        text: "Are you sure you want to complete this transaction?",
                        icon: "info",
                        buttons: ["Cancel", "Confirm"]
                    }).then(async (res) => {
                        if (res) {
    
                            console.log("======nftMinterContract====", nftMinterContract)
                            // const signerAddress = await signer.getAddress();
                            // console.log("======signerAddress====",signerAddress)
                            //   const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
                            //     const gasLimit = await nftMinterContract.estimateGas.mintNFT(nft_type);
                            //    const gasPrice = await ethersProvider.getGasPrice();
                            const tx = await nftMinterContract.mintNFT(nft_type, {
                                gasLimit: 500000, // Fallback if gas estimation fails
                            })
                            console.log("======tx====", tx)
                            const receipt = await tx.wait()
                            if (receipt) {
                                setRefreshHook(new Date());
                                setIsLoading(false); // Set the loader for the modal
                                setShowStepsModal(false);
                                swal("Success", "NFT minted successfully!", "success");
                            }
                        } else {
                                // swal("Error", "User denied transaction.", "error");
                    setShowStepsModal(false);
                    setIsLoading(false); // Set the loader for the modal
                }
            })

        } else {

            const estimatedGas = await nftMinterContract.methods.mintNFT(nft_type).estimateGas({from:sendParams.from});


            // Set gas limit with a buffer
            sendParams.gas = Math.ceil(Number(estimatedGas) * BASE_GAS_MULTIPLIER);

            // Send the transaction
            const receipt = await nftMinterContract.methods.mintNFT(nft_type).send(sendParams);

            // Refresh hook and notify success
            setRefreshHook(new Date());
            setIsLoading(false); // Set the loader for the modal
            setShowStepsModal(false);
            swal("Success", "NFT minted successfully!", "success");

            console.log('Transaction successful with hash:', receipt.transactionHash);

        }
    } catch (error) {
        console.error("Error minting NFT:", error, error.data);
        let err = error.toString();
        if (error.code == 4001) {
            swal("Error", "User denied transaction.", "error");
        } else if (err.includes("User denied transaction")) {
            swal("Error", "User denied transaction.", "error");
        } else if (err.includes("user rejected")) {
            swal("Error", "User denied transaction.", "error");
        } else {
            swal("Error", "Minting failed. Please try again.", "error");
        }
        setIsLoading(false); // Set the loader for the modal
        setShowStepsModal(false);
    }
};

// ---------------- get code end
    

    const getFolioContract = () => {
        console.log("===========================", localStorage.getItem("user_type"))
        let folioContract
        let web3 = new Web3(window.ethereum);
        if (localStorage.getItem("user_type") == "CUSTODIAL") {
            const web3 = new Web3(web3AuthProvider);
            const ethersProvider = new ethers.providers.Web3Provider(web3AuthProvider);
            console.log("====sign=======", web3AuthProvider)
            const signer = ethersProvider.getSigner();
            folioContract = new ethers.Contract(
                folioContractAddress,
                tokenAbi,
                signer
            );
        } else {
            folioContract = new web3.eth.Contract(tokenAbi, folioContractAddress);
        }
    }

    // Function to get the balance of a specific address
    const getFolioBalance = async () => {
        try {
            let balance;
            let approvedFolio;
            console.log(localStorage.getItem("user_type"))

            let web3 = new Web3(web3AuthProvider);

            let folioContract = new web3.eth.Contract(tokenAbi, folioContractAddress);
            console.log("====folioContractAddress========", folioContract)
            if (localStorage.getItem("user_type") == "CUSTODIAL" && folioContract) {
                console.log("====userAddress====CUSTODIAL====", userAddress)
                console.log("====userAddress====CUSTODIAL====", folioContract)
                //  balance = await folioContract.balanceOf(userAddress);
                balance = await folioContract.methods.balanceOf(userAddress).call();


                approvedFolio = await folioContract.methods.allowance(userAddress, nftMinterAddress).call();

            } else {
                balance = await folioContract.methods.balanceOf(userAddress).call();
                approvedFolio = await folioContract.methods.allowance(userAddress, nftMinterAddress).call();
            }

            console.log("approvedFolio", approvedFolio);
            console.log(`Folio Balance of ${userAddress}: ${balance}`);
            setFolioBalanceWei(parseInt(balance))
            setFolioAllowance(parseInt(approvedFolio))
            return balance;
        } catch (error) {
            console.error('Error fetching balance:', error);
        }
    };
    /* get user points */
    const get_userPoints = async () => {
        setLoader(true);
        try {
            const response = await apiService.getRedmeedPoints();
            if (response.status === 200) {
                console.log("response.data.data>>>>>get_userPoints", response.data);
                setUserRedemptions(response.data?.data?.userRedemptions);
                let bal = (parseInt(response?.data?.data?.rewardPoints?.points) - (parseInt(response?.data?.data?.rewardPoints?.redemmed_points) || 0)) || 0
                setBalance(bal > 0 ? bal : 0)
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            // if (error?.response?.status === 401) {
            //     swal({ text: "Unauthorized", button: "OK" }).then(() => {
            //         localStorage.clear();
            //         window.location.href = "/";
            //     });
            // }
        }
    };
    const [totalPoints, setTotalPoints] = useState(0);

    /* get user points */
    const getBonusPoint = async () => {
        setLoader(true);
        try {
            const response = await apiService.getBonusPoint();
            if (response.status === 200) {
                const bonusPoint = response.data.data.totalPoints;
                // setTimeout(()=>{
                setTotalPoints(bonusPoint);
                // },bonusPoint);

            }
        } catch (error) {
            setLoader(false);
            // if (error?.response?.status === 401) {
            //     swal({ text: "Unauthorized", button: "OK" }).then(() => {
            //         localStorage.clear();
            //         window.location.href = "/";
            //     });
            // }
        }
    };

    let renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Closed</span>;
        } else {
            return (
                <span>
                    {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}
                </span>
            );
        }
    };
    function getTodayEndUTC() {
        const now = new Date();
        const todayEndUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));
        return todayEndUTC;
    }

    /* get redmee list */
    const get_RedmeeList = async (filterKey = "All", pageSel) => {
        setLoader(true);
        try {
            const response = await apiService.getRedmeedList(filterKey, pageSel);
            if (response.status === 200) {
                console.log("get_RedmeeList .data>>>>>", response.data?.data);
                setRedmeeList(response?.data?.data?.folioPoints);
                setLoader(false);
                settotalCount(response?.data?.data?.totalRecordsCount)
                setTotalFolioRedeemed(response?.data?.data?.totalFolioRedeemed);
            }
        } catch (error) {
            setLoader(false);
            // if (error?.response?.status === 401) {
            //     swal({ text: "Unauthorized", button: "OK" }).then(() => {
            //         localStorage.clear();
            //         window.location.href = "/";
            //     });
            // }
        }
    };

    const handleFilterChange = (selectedOption) => {
        setFilter(selectedOption.value);
        setpage(0);
        get_RedmeeList(selectedOption.value, 0);

    };


  
    const adData = adConfig["Redeem"]; // Get specific page ad config

    return (
        <>
            {window.innerWidth <= 768 ? (
                <AdComponent adId={adData.ids[3]} dimension={adData.dimensions[3]} className={"ads-banner-top"} />) :
                (
                    <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} />)}
            {/* <AdComponent adId={adData.ids[0]} dimension={adData.dimensions[0]} className={"ads-banner-top"} /> */}

            <AdComponent adId={adData.ids[1]} dimension={adData.dimensions[1]} className={"ads-home-right"} />

            <AdComponent adId={adData.ids[2]} dimension={adData.dimensions[2]} className={"ads-home-left"} />

            <section className="redem-outer-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} lg={10} xxl={10}>
                            <div className="redem-inner-area">
                                <h2>FOLIO Redemption</h2>
                                <div className="redem-top-info">
                                    <h6>What is FOLIO?</h6>
                                    {/* <p>FOLIO are tokenized points redeemable for BetFolio NFTs which allow you to bet on <a href="https://betfolio.co/solo-predictions" target="blank">Solo</a> and <a href="https://betfolio.co/multi-choice-predictions" target="blank">Multichoice</a> predictions for free.</p>
                                    <p>You can earn FOLIO by accumulating points when you place or win a bet on any prediction. Once you have minimum 100 points, you can convert it to FOLIO.
                                        You can then redeem your FOLIO for an NFT on this page.</p>
                                    <p>Make sure your wallet is connected to the Polygon network to check your points and FOLIO balance and to convert or redeem.</p>
                                    <ul>
                                        <li>The maximum amount of FOLIO that can be redeemed by a single user in a 30 day period is 2000 FOLIO.</li>
                                    </ul> */}
                                    <p>FOLIO are tokenized points redeemable for our BetFolio NFTs which allow you to earn a <a className="text-redeem" href="https://betfolio-discount-nft-10.nfts2.me/" target="_blank">10%</a>, <a className="text-redeem" href="https://betfolio-discount-nft-25.nfts2.me/" target="_blank">25%</a>, <a href="https://betfolio-discount-nft-50.nfts2.me/" target="_blank" className="text-redeem">50%</a> and <a href="https://betfolio-discount-nft-70.nfts2.me/" target="_blank" className="text-redeem">70%</a> discount on BetFolio platform fees for 30 days.</p>
                                    <p>You can earn FOLIO by accumulating points when you place or win a bet on any prediction. Once you have minimum 100 points, you can convert it to FOLIO. You can then redeem your FOLIO for an NFT on this page.</p>
                                    <p>Make sure your wallet is connected to the Polygon network to check your points and FOLIO balance and to convert or redeem.</p>
                                    <ul>
                                        <li>The maximum amount of FOLIO that can be redeemed by a single user in a 30 day period is 10,000 FOLIO.</li>
                                    </ul>
                                    {/* <Row className="justify-content-center">
                                        <Col md={6}>
                                            <div className={`bonus-points`}>
                                                <h6>Welcome bonus points</h6>
                                                <p class="animate-charcter">{totalPoints}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="bonus-countdown">
                                        <Countdown date={moment(getTodayEndUTC().getTime()).format("YYYY-MM-DD HH:mm:ss")} renderer={renderer} />
                                    </div>
                                    <h6 class="animate-charcter">Max. 10,000 claimable per day</h6> */}
                                </div>
                                <Row className="justify-content-center">
                                    <Col md={6} lg={4} className="mb-2">
                                        <div className="redem-points-area balance-area">
                                            <h6>Balance: <span>{balance}</span> pts</h6>
                                            <p>Points</p>
                                            <Form.Control
                                                type="text"
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                placeholder="100"
                                                maxLength={10}
                                            />
                                            <ul>
                                                {percentages.map((percentage, index) => (
                                                    <li
                                                        key={index}
                                                        className={activePercentage === percentage ? "active" : ""}
                                                        onClick={() => handlePercentageClick(percentage)}
                                                    >
                                                        {percentage}%
                                                    </li>
                                                ))}
                                            </ul>
                                            <img src={require("../assets/images/double-direction-arrow.svg").default} alt="Double Direction Arrow" />
                                        </div>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <div className="redem-points-area">
                                            <p>FOLIO</p>
                                            <h3>{folioValue}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Button type="button" variant="unset" disabled={!isConnected || inputValue < 1 || inputValue > balance || balance < 100} onClick={handleConvertRedeem}>Convert to FOLIO</Button>
                                <h6>1 Point on BetFolio = 1 FOLIO<span>*</span></h6>
                            </div>
                            <hr />
                            <Row className="mb-4 justify-content-center">
                                <Col sm={12} md={9} lg={7} xl={6} xxl={5}>
                                    <div className="redem-points-area folio-amount-area">
                                        <p>FOLIO Balance: <span>{Number(formatBalanceWithDecimals(folioBalanceWei, folioDecimals)).toFixed(2)}</span> FOLIO</p>
                                       <div className="discount-bottom-btn">
                                            <div>
                                                <Button type="button" variant="unset"  className="twity-five-discount mb-2"   disabled={formatBalanceWithDecimals(folioBalanceWei, folioDecimals) < getRequiredFolio(11)} onClick={() => redeemFolio(11)} >10% Discount - 300 FOLIO</Button>
                                                <Button type="button" variant="unset"  className="twity-five-discount "   disabled={formatBalanceWithDecimals(folioBalanceWei, folioDecimals) < getRequiredFolio(9)} onClick={() => redeemFolio(9)} >25% Discount - 750 FOLIO</Button>
                                            </div>
                                            <div>
                                                <Button type="button" variant="unset" className="twity-five-discount mb-2" disabled={formatBalanceWithDecimals(folioBalanceWei, folioDecimals) < getRequiredFolio(10)} onClick={() => redeemFolio(10)} >50% Discount - 2500 FOLIO</Button>
                                                <Button type="button" variant="unset" className="twity-five-discount" disabled={formatBalanceWithDecimals(folioBalanceWei, folioDecimals) < getRequiredFolio(12)} onClick={() => redeemFolio(12)} >70% Discount - 5000 FOLIO</Button>
                                            </div>
                                       </div>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row className="mb-4 justify-content-center">
                                <Col md={12}>
                                    <div className="redemption-options">
                                        <h6><i className="fa fa-info-circle" aria-hidden="true"></i> You can only redeem 1 NFT at a time. Select from the 3 redemption options:</h6>
                                        <ul>
                                            <li>You need minimum <span>100 FOLIO</span> to redeem for <span>1 Multichoice NFT.</span></li>
                                            <li>You need minimum <span>100 FOLIO</span> to redeem for <span>1 Solo NFT.</span></li>
                                            <li>You need minimum <span>300 FOLIO</span> to redeem for <span>1 Extended Solo NFT.</span></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row> */}
                            <hr />
                            <Row className="justify-content-center">
                                <Col md={12}>
                                    <div className="redem-history">
                                        <h5>Redeem History</h5>
                                    </div>
                                    <div className="audit-request-box leaderboard-inner">
                                        <div className="board-filters redem-history-filter">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Status:</Form.Label>
                                                <Select
                                                    options={PointsProgram}
                                                    placeholder="All"
                                                    isDisabled={!isConnected}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            background: "#1d0042",
                                                            border: "1px solid rgb(255 255 255 / 50%)",
                                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                                                            borderRadius: "10px",
                                                            "&:focus": {
                                                                borderColor: "rgb(255 255 255 / 50%)",
                                                            },
                                                            "&:hover": {
                                                                borderColor: "rgb(255 255 255 / 50%)",
                                                            },
                                                        }),
                                                    }}
                                                    onChange={handleFilterChange}
                                                />
                                            </Form.Group>
                                        </div>
                                        <Table responsive="sm" className="UserListTable">
                                            <thead>
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th className="leaderboard-address">Wallet Address</th>
                                                    <th className="leaderboard-address">Redeem</th>
                                                    <th className="leaderboard-address">Collected</th>
                                                    <th className="leaderboard-address">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {redmeeList?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{(page * 10) + index + 1}</td>
                                                            <td>{formatWalletAddress(item.user_wallet_address)}</td>

                                                            {item.type == "point" ?
                                                                <>
                                                                    <td>{item.points_redeem + " points" || "-"}</td>
                                                                    <td>{item.folio_points + " FOLIO" || "-"}</td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>{item.points_redeem + "FOLIO" || "-"}</td>
                                                                    <td>{NFT_NAME[(item.folio_points || 0) - 1]}</td>
                                                                </>

                                                            }
                                                            <td>{item?.status || "-"}</td>
                                                        </tr>

                                                    )
                                                })}
                                                {redmeeList && redmeeList.length == 0 &&
                                                    <tr>
                                                        <td className="board-no-data-outer">
                                                            <div className="no-data-area main-no-data table-no-data board-no-data">
                                                                <img src={require("../assets/images/leader-board-no-data.png")} alt="img" />
                                                                <p> No Data Found</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                        {redmeeList && redmeeList.length > 0 ?
                                            <Pagination
                                                activePage={page + 1}
                                                itemsCountPerPage={10}
                                                totalItemsCount={totalCount}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => handlePageChange(e)}
                                                prevPageText={"Prev"}
                                                nextPageText={"Next"}
                                            />
                                            : null
                                        }


                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                show={showStepsModal}
                backdrop="static"
                onHide={handleCloseStepsModal}
                centered
                className="connect-wallet-box follow-steps-popup"
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="mt-0">Follow Steps </h3>
                    <div className="follow-steps-ad-mid">
                        <span id="ct_cXLTfT9UNz7"></span>
                    </div>
                    <div className="step-area">
                        <div className="steps-left-area">
                            {!tokenApproved && isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                <h2>
                                    ✓
                                </h2>
                            )}
                        </div>

                        <div className="steps-content">
                            <h6> Approve FOLIO</h6>
                            <p>Approving FOLIO</p>
                        </div>
                    </div>
                    <div className="step-area">
                        <div className="steps-left-area">
                            {tokenApproved && isLoading ? (
                                <div className="loader"></div>
                            ) : (
                                !tokenApproved && isLoading ? <h2>2</h2> :
                                    <h2>
                                        ✓
                                    </h2>
                            )}
                        </div>

                        <div className="steps-content">
                            <h6> Mint NFT</h6>
                            <p>Send transaction to mint nft</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            {/* --- ALERT MODAL AREA --- */}
            <Modal show={warningModalshow} onHide={handleWarningModalClose} centered className="alert-box">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>
                        <img className="alert-icon" src={require("../assets/images/alert-icon.svg").default} alt="alert || Betfolio" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert-content">
                        <h4>Warning!!!</h4>
                        <p>You already have a discount NFT. If you purchase a new one, it cannot be used until your existing discount NFT expires.</p>
                        <Button className="alert-btn" onClick={handleProceedAnyway} >Proceed anyway</Button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* --- ALERT MODAL AREA --- */}
        </>
    );
}

export default Redem;
