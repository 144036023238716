import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import { Container } from "react-bootstrap";
import { erc721Abi } from "../config/erc721Abi";
import { aiGatewayAbi } from "../config/aiGatewayAbi";
import { aiGatewayAddress, tokenAddress, WEB3_PROVIDER, web3auth } from "../config/config";
import Web3 from "web3";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import ApprovalModal from "./ApprovalModal";
import Promptbuilder from "./promptbuilder";
import { apiService } from "../service/api.service";

const Benjamin = () => {
  const [account, setAccount] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tokenApproved, setTokenApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [minting, setMinting] = useState(false);
  const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
  const walletAddress = useSelector((state) => state.reducer.walletAddress)
  const [showPromptbuilder, setShowPromptbuilder] = useState(false)
  const promptBuilderRef = useRef(null);

  let aiGatewayPrice = 10;
  const [aiData, setAIData] = useState([
    {
      cover: "ai-cover.png",
      link: "https://chatgpt.com/g/g-67a77f8c2eb88191a2beec406d9e8dad-benjamin-hourly-prediction-results",
      description: <><b>Benjamin - Hourly Prediction Results.</b> BetFolio's Custom AI chatbot - Get tips on bets to place and tokens to buy based on the past 7 days of hourly prediction results.</>,
    },
    {
      cover: "ai-cover2.png",
      link: "https://chatgpt.com/g/g-67a83194f5408191aea014e875518391-benjamin-daily-prediction-results",
      description: <><b>Benjamin - Daily Prediction Results.</b> Get AI-driven insights on token movements from the past 24 hours to optimize your trades.</>,
    },
    {
      cover: "ai-cover3.png",
      link: "https://chatgpt.com/g/g-67aa5478482c8191ac62a8b0f586f5cf-benjamin-memecoin-index-return-data",
      description: <><b>Benjamin - Memecoin Index Returns.</b> Analyze the top-performing memecoins over the last week with AI-backed predictions.</>,
    },
    {
      cover: "ai-cover4.png",
      link: "https://chatgpt.com/g/g-67b39b1790d081918dbc948302515e48-benjamin-trending-pump-fun-token-data",
      description: <><b>Benjamin - Trending Pump.fun Token Data.</b> Identify the top 20 trending Pump.fun tokens to optimize your trades.</>,
    },
    {
      cover: "ai-cover5.png",
      link: "https://chatgpt.com/g/g-67b570e857148191b26cc4ddc52a4420-benjamin-football-match-final-scores-data",
      description: <><b>Benjamin - Football Match Final Scores.</b> AI-driven predictions and final score results for major football matches.</>,
    },
  ]);

  const getProvider = (web3AuthProvider) => {
    console.log("checkSubscription", web3AuthProvider)
    if (localStorage.getItem("user_type") === "NON-CUSTODIAL") {
      console.log("window.ethereum is used", window.ethereum)
      return new ethers.providers.Web3Provider(window.ethereum);
    } else if (localStorage.getItem("user_type") === "CUSTODIAL") {
      console.log("web3auth is used", web3AuthProvider)
      return new ethers.providers.JsonRpcProvider(web3AuthProvider);
    }
  };


  useEffect(() => {
    console.log("checkSubscription useffect");
    setAccount(localStorage.getItem("connected_wallet"));
    fetchBenjaminNftData()
  }, []);

  async function fetchBenjaminNftData() {
    const data = await apiService.getBenjaminNft();

    setAIData((prevAIData) =>
      prevAIData.map((aiItem) => {
        const matchingNft = data.data.find((nft) =>
          aiItem.description.props.children[0].props.children === nft.title
        );

        return matchingNft
          ? { ...aiItem, link: matchingNft.link }
          : aiItem;
      })
    );

  }

  useEffect(() => {
    checkSubscription();
  }, [walletAddress, web3auth?.provider, web3AuthProvider]);


  const checkConnection = async () => {
    let web3 = new Web3(window.ethereum);
    if (localStorage.getItem("user_type") === "CUSTODIAL") {
      web3 = new Web3(web3AuthProvider);
    }

    try {
      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) {
        localStorage.clear();
        swal("Error", "No wallet connected. Please connect your wallet.", "error");
        return false;
      }

      const connectedWallet = localStorage.getItem("connected_wallet") || "";
      if (accounts[0].toLowerCase() !== connectedWallet.toLowerCase()) {
        swal("Warning", "You've linked to a different wallet address. Kindly verify in MetaMask.", "warning");
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error checking connection:", error);
      return false;
    }
  };

  const checkSubscription = async () => {
    console.log("checkSubscription>>>>>>> 11111", walletAddress)
    if (!walletAddress) {
      console.log("checkSubscription account--->", walletAddress)
      return
    }
    try {
      // console.log("checkSubscription web3auth",web3auth)
      // console.log("checkSubscription checkSubscription try>>>>>>>");
      // const provider = getProvider(web3auth?.provider)
      // console.log("checkSubscription provider>>>>>>>",provider)
      // const contract = new ethers.Contract(aiGatewayAddress, aiGatewayAbi, provider);
      // console.log("checkSubscription contract>>>>>>>",contract)
      // console.log("checkSubscription account>>>>>>>",account)
      // const active = await contract.hasActiveNFT(account)
      // console.log("checkSubscription active>>>>>>>",active);
      // setIsSubscribed(active)

      let web3
      if (localStorage.getItem("user_type") == "NON-CUSTODIAL") {
        console.log("checkSubscription provider>>>>>>>", window.ethereum);
        web3 = new Web3(window.ethereum);
      } else {
        console.log("checkSubscription provider>>>>>>>", web3AuthProvider);
        web3 = new Web3(web3AuthProvider);
      }
      console.log("checkSubscription web3>>>>>>>", web3, web3AuthProvider);
      const wallet_address = await web3.eth.getAccounts();
      console.log("checkSubscription wallet_address", wallet_address[0]);


      // Load the contract
      const contract = new web3.eth.Contract(aiGatewayAbi, aiGatewayAddress);
      console.log("checkSubscription contract>>>>>>>", contract);

      console.log("checkSubscription account>>>>>>>", wallet_address[0]);

      const active = await contract.methods.hasActiveNFT(wallet_address[0]).call();
      console.log("checkSubscription active>>>>>>>", active);
      setIsSubscribed(active)
    } catch (error) {
      console.error("checkSubscription Error checking subscription:", error);
    }
  };

  const buyNFTCommonFunc = async (signer) => {
    setIsLoading(true)
    try {
      const contract = new ethers.Contract(aiGatewayAddress, aiGatewayAbi, signer);

      let gasLimit
      try {
        gasLimit = await contract.estimateGas.buyNFT();
        console.log("Estimated Gas:", gasLimit.toString());

        // Increase gas limit by 20%
        gasLimit = gasLimit.mul(120).div(100);
      } catch (error) {
        if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
          // Set a default gas limit to bypass estimation error
          gasLimit = ethers.utils.hexlify(500000); // Set a fixed gas limit
        } else {
          throw error
        }
      }

      // Estimate gas
      const gasPrice = await signer.getGasPrice();

      // Increase gas price by 20%
      const increasedGasPrice = gasPrice.mul(120).div(100);

      // Send transaction with increased gas limit and price
      const tx = await contract.buyNFT({
        gasLimit: gasLimit,
        gasPrice: increasedGasPrice
      });

      await tx.wait();
      console.log("Transaction successful");
      setMinting(false);

      swal("Success", "NFT purchased successfully!", "success");
      checkSubscription();
    } catch (err) {
      console.log(err)
    }

  }

  const buyNFT = async () => {
    const isConnected = await checkConnection();
    if (!isConnected) return;

    setShowModal(true);
    setIsLoading(true); // Show loader for approval step

    try {
      const provider = new ethers.providers.Web3Provider(web3AuthProvider);
      // const provider = getProvider(ethersProvider);
      console.log("provider", provider);

      const signer = provider.getSigner();
      const userAddress = await signer.getAddress();
      console.log("tokenAddress", tokenAddress);
      const usdcContract = new ethers.Contract(tokenAddress, [
        "function balanceOf(address owner) public view returns (uint256)",
        "function approve(address spender, uint256 amount) public returns (bool)",
        "function allowance(address owner, address spender) public view returns (uint256)"
      ], signer);

      const maticBalance = await provider.getBalance(userAddress);
      const formattedMaticBalance = parseFloat(ethers.utils.formatEther(maticBalance));


      const usdcBalance = await usdcContract.balanceOf(userAddress);
      const formattedUsdcBalance = parseFloat(ethers.utils.formatUnits(usdcBalance, 6));

      console.log("MATIC Balance:", formattedMaticBalance);
      console.log("USDC Balance:", formattedUsdcBalance);

      if (formattedMaticBalance < 0.1 || formattedUsdcBalance < aiGatewayPrice) {
        swal("Error", "Insufficient POL for gas fees or insufficient USDC balance!", "error");
        return false;
      }



      const allowance = await usdcContract.allowance(userAddress, aiGatewayAddress);

      const cost = ethers.utils.parseUnits(aiGatewayPrice.toString(), 6);
      console.log("allowance", allowance.toString(), cost.toString());

      if (cost < allowance) {
        console.log("Sufficient allowance, no need to approve.");
        setTokenApproved(true);
        setMinting(true);
      } else {
        // Step 1: Approve USDC
        if (localStorage.getItem("user_type") === "CUSTODIAL") {
          const approve = await swal({
            text: "Are you sure you want to approve this transaction?",
            icon: "info",
            buttons: ["Cancel", "Approve tokens"],
          })
          if (approve) {
            try {
              const approvalTx = await usdcContract.approve(aiGatewayAddress, cost);
              console.log("approvalTx", approvalTx);

              await approvalTx.wait();
              setTokenApproved(true); // Mark approval as complete
              setMinting(true);
            } catch (error) {
              console.log(error)
            }
          } else {
            setTokenApproved(false); // Mark approval as complete
            setIsLoading(false);
            setShowModal(false);
            setMinting(false);
            return
          }

        } else {
          const approvalTx = await usdcContract.approve(aiGatewayAddress, cost);
          await approvalTx.wait();
          setTokenApproved(true); // Mark approval as complete
          setMinting(true);
        }

      }

      // Step 2: Mint NFT
      if (localStorage.getItem("user_type") === "CUSTODIAL") {
        const approve = await swal({
          text: "Are you sure you want to complete this transaction?",
          icon: "info",
          buttons: ["Cancel", "Confirm"],
        })
        if (approve) {
          await buyNFTCommonFunc(signer)
        } else {
          setTokenApproved(false); // Mark approval as complete
          setIsLoading(false);
          setShowModal(false);
          setMinting(false);
          return
        }

      } else {
        await buyNFTCommonFunc(signer)
      }



    } catch (error) {
      console.error("Purchase failed:", error);
      checkSubscription();
      swal("Error", "Transaction failed!", "error");

    } finally {
      setShowModal(false);
      setTokenApproved(false);
      console.log("minting setting false")
      setMinting(false);
      setIsLoading(false);
      checkSubscription();
    }
  };


  return (
    <section className="benjamin-ai">
      <Container>
        <h2>Benjamin - AI</h2>
        <div className="description-area">
          <p>Your premier betting and trading intelligence tool. Benjamin AI offers insights on which bets to place and trades to take using: </p>
          <ul>
            <li>The past 7 days of hourly prediction results</li>
            <li>The past 3 months of daily prediction results</li>
            <li>The past 3 days of data from the Top 20 Trending Pump.fun tokens.</li>
            <li>The past 3 days of data from the Top 3,000 Memecoins on Coinmarketcap.com</li>
            <li>Historical data from every major football/soccer match in the past 3 years.</li>
          </ul>
          <p>Leverage Benjamin AI's insights to optimize your betting and trading experience in the market and on any betting platform. </p>
          <div className="subscribe-btn  mt-4">
            <button className="text-white rounded-3" onClick={buyNFT} disabled={isLoading || minting || isSubscribed}>
              {isSubscribed ? "Subscribed" : isLoading || minting ? "Processing..." : "Subscribe"}
              {console.log("minting nnn", minting, isSubscribed, isLoading)}
            </button>
            <button disabled={!isSubscribed} style={{ cursor: isSubscribed ? "pointer" : "not-allowed" }} className='prompt rounded-3 bg-transparent' onClick={
              () => {
                if (isSubscribed) {
                  setShowPromptbuilder(true)
                  promptBuilderRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
                } else if (!isSubscribed) {
                  setShowPromptbuilder(false)
                }
              }
            }>Prompt Builder</button>
          </div>
        </div>
        <div className="cards-row">
          {aiData.map((item, index) => (

            <div className="feature-card-boxe" key={index} onClick={isSubscribed ? () => window.open(item.link, "_blank") : undefined}
              style={{ cursor: isSubscribed ? "pointer" : "not-allowed" }}  >
              <div className={`img-box rounded-2 text-center position-relative ${isSubscribed ? "subscribed" : "non-subscribed"}`}>
                <img src={require(`../assets/images/${item.cover}`)} alt={`ai-cover-${index}`} />
                {!isSubscribed && <img src={require("../assets/images/overlay.png")} alt="overlay" className="overlay-lock" />}
              </div>
              <div className="ai-feature">
                <p>{item.description}</p>
              </div>
            </div>

          ))}

        </div>

      </Container>

      <div ref={promptBuilderRef}>
        {showPromptbuilder && <Promptbuilder />}
      </div>

      {/* Approval Modal */}
      <ApprovalModal
        show={showModal}
        onClose={() => setShowModal(false)}
        tokenApproved={tokenApproved}
        isLoading={isLoading}
        minting={minting}
      />
    </section>
  );
};

export default Benjamin;
