import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { checkProvider } from "../common/checkProvider";
import {
  Handleconnect,
  formatChainAsNum,
  formatAddress,
  formatBalance,
  formatBalanceWithDecimals,
} from "../common/connectWallet";
import { useEffect, useRef, useState } from "react";
import { setWalletAddress, setwalletConnected } from "../store/index_slice";
import { apiService } from "../service/api.service";
import { useSelector, useDispatch } from "react-redux";
import NotConnectWalletPopup from "../common/NotConnectWalletPopup";
import ConnectWalletPopup from "../common/ConnectWalletPopup";
import swal from "sweetalert";
import { ThreeDotSpinner, InlineLoader } from "./loader/index";
import PredictionBox from "./PredictionBox";
import SoloTypeTooltip from "./SoloTypeTooltip";
import SearchComponent from "../common/SearchComponent";
import { adClient } from "../common/adConfig";
import { adConfig, web3auth } from "../config/config";
import RPC from "../common/web3RPC";
import Web3 from "web3";
import AdComponent from "../common/AdComponent";

const Home = () => {
  const swiperRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showconnect, setShowconnect] = useState(false);
  const handleCloseConnect = () => {
    setShowconnect(false);
    window.location.reload();
  };
  const predictionAdsRef = useRef(-2);
  const [predictionFilter, setpredictionFilter] = useState("");
  const handleShowConnect = () => setShowconnect(true);
  const iswallet = useSelector((state) => state.reducer.walletConnected);
  const dispatch = useDispatch();
  const [hasProvider, setHasProvider] = useState(false);
  const navigationPrevRef = useRef(null);
  const [globalpredictionFilter, setGlobalPredictionFilter] = useState();
  const [loader, setLoader] = useState(false);
  const [predictions, setpredictions] = useState([]);
  const [limit, setlimit] = useState(9);
  const [totalRecords, settotalRecords] = useState(0);
  const [duration, setduration] = useState("");
  const [type, settype] = useState("");
  const [dataProvider, setDataProvider] = useState("");
  const [isSoloPresiction, setIsSoloPrediction] = useState(false);
  const [isMultiChoicePresiction, setIsMultiChoicePrediction] = useState(false);
  const [soloStatus, setSoloStatus] = useState("Open");
  const [soloTheme, setSoloTheme] = useState("All");
  const [valueFilter, setValueFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const inputRef = useRef(null);
  const [showAd, setShowAd] = useState(false); // Step 1: State to track ad visibility

  /* banner add for home page on top */

  // adClient.showBannerAd({
  //   adUnitId: "271833e4-a718-4d71-b73e-955e35b1b33d", // live
  //   // adUnitId: "23a5da96-5f02-4884-b4f9-8ca6a5bc95d7", // testing
  //   containerId: "ads-banner-top"
  // }, (errorMessage) => {
  //   console.log("errorMessage::::::::::", errorMessage);
  //   // You can handle error here.
  // })

  // useEffect(() => {
  //   /* banner ads for home page list */
  //   adClient.showBannerAd({
  //     adUnitId: "ce054f39-37b2-4aa6-9fde-737c17ab955f", // live
  //     // adUnitId: "ff7909c2-a6ea-41bc-a544-0bad0f13ede6", // testing
  //     containerId: "contraffic_home_add"
  //   }, (errorMessage) => {
  //     console.log("errorMessage::::::::::", errorMessage);
  //     // You can handle error here.
  //   })
  // }, [showAd])

  // adClient.showBannerAd({
  //   adUnitId: "735e6f3c-15d0-4766-9416-237cdd7198de",
  //   containerId: "ads-home-left"
  // }, (errorMessage) => {
  //   console.log("errorMessage",errorMessage);
  //   // You can handle error here.
  // })

  // adClient.showBannerAd({
  //   adUnitId: "591b109d-963c-4173-804b-817eeeaa696a",
  //   containerId: "ads-home-right"
  // }, (errorMessage) => {
  //   console.log("errorMessage", errorMessage);
  // })

  {
    iswallet &&
      adClient.setWalletAddress(localStorage.getItem("connected_wallet"));
  }

  const options = [
    { value: "", label: "All" },
    { value: "DAY", label: "1 day" },
    { value: "WEEK", label: "1 week" },
    { value: "MONTH", label: "1 month" },
  ];
  // isSoloPresiction

  const soloFilterOptions = [
    { value: "All", label: "All" },
    { value: "Open", label: "Open" },
    { value: "time-extended", label: "Open: Extra Time" },
    { value: "Open_Bet_More", label: "Open: Bet More" },
    { value: "Close", label: "Close" },
  ];

  const soloFilterOptionsOld = [
    { value: "All", label: "All" },
    { value: "Open", label: "Open" },
    { value: "time-extended", label: "Open: Extra Time" },
    { value: "Open_Bet_More", label: "Open More Bet" },
    { value: "Close", label: "Close" },
  ];

  const optionsFilter = [
    { value: "All", label: "All" },
    { value: "Upcoming", label: "Upcoming" },
    { value: "In-Progress", label: "In-Progress" },
    { value: "Past", label: "Past" },
  ];
  const optionsPredictions = [
    { value: "", label: "All" },
    // { value: "PAID", label: "Paid" },
    { value: "RISK-FREE", label: "Prize pool" },
  ];

  const soloThemeOptions = [
    { value: "All", label: "All" },
    { value: "brands", label: "Brands" },
    { value: "crypto-market", label: "Crypto Market" },
    { value: "fundamentals", label: "Fundamentals" },
    { value: "games", label: "Games" },
    { value: "pop-culture", label: "Pop Culture" },
    { value: "sports", label: "Sports" },
    { value: "stock-market", label: "Stock Market" },
    { value: "location", label: "Location" },
  ];

  const prizePools = {
    pearls: "Pearl: $5 Prize Pool",
    sapphires: "Sapphire: $50 Prize Pool",
    rubies: "Ruby: $100 Prize Pool",
    emeralds: "Emerald: $250 Prize Pool",
    diamonds: "Diamond: $500 Prize Pool",
  };

  // ["Pearls", "Sapphires", "Rubies", "Emeralds","Diamonds"]

  //
  const valueFilterOptions = [
    { value: "All", label: "All" },
    { value: "pebbles", label: "Pebbles" },
    { value: "pearls", label: "Pearls" },
    { value: "sapphires", label: "Sapphires" },
    { value: "rubies", label: "Rubies" },
    { value: "emeralds", label: "Emeralds" },
    { value: "diamonds", label: "Diamonds" },
  ];

  const params = useParams();
  const navigate = useNavigate();
  const { referralCode } = params;
  const [isScoresPresiction, setIsScoresPresiction] = useState(false);
  const [isPumpFunPresiction, setIsPumpFunPresiction] = useState(false);


  let pathname = window.location.pathname;
  useEffect(() => {
    localStorage.removeItem("isReloaded");
    // console.log("params usefeefect", params);
    // console.log("params usefeefect pathname", pathname);
    // console.log("params usefeefect pathname dataProviderParam", params.dataProvider);
    // console.log("params usefeefect pathname dataProviderParam", params.dataProvider);
    if (pathname == "/") {
      setIsSoloPrediction(false);
      setIsMultiChoicePrediction(false);
      setIsScoresPresiction(false);
      setIsPumpFunPresiction(false);
    }
    // const searchParams = params.dataProvider;
    const dataProviderParam = params.dataProvider;
    if (dataProviderParam == "pump-fun") {
      setIsSoloPrediction(false);
      setIsMultiChoicePrediction(false);
      setIsScoresPresiction(false);
      setIsPumpFunPresiction(true);
    } else if (dataProviderParam == "scores") {
      setIsSoloPrediction(false);
      setIsMultiChoicePrediction(false);
      setIsScoresPresiction(true);
      setIsPumpFunPresiction(false);
    } else if (dataProviderParam == "solo-predictions") {
      setIsSoloPrediction(true);
      setIsMultiChoicePrediction(false);
      setIsScoresPresiction(false);
      setIsPumpFunPresiction(false);
    } else if (dataProviderParam == "multi-choice-predictions") {
      setIsSoloPrediction(false);
      setIsMultiChoicePrediction(true);
      setIsScoresPresiction(false);
      setIsPumpFunPresiction(false);
    } else if (dataProviderParam) {
      // console.log("solo param if>>>")
      setDataProvider(dataProviderParam);
      // get_prediction(limit, duration, type, predictionFilter, dataProviderParam, searchTerm);
    } else {
      // console.log("solo dataProviderParam", "else")
      // console.log("solo param else>>>")
      setHasProvider(checkProvider());
      // get_prediction(limit, duration, type, predictionFilter, '', searchTerm);
    }
  }, [pathname]);

  useEffect(() => {
    if (soloStatus && soloTheme && valueFilter) {
      get_prediction(9, searchTerm);
    }
  }, [soloStatus, soloTheme, valueFilter, pathname]);

  const connectWallet = async () => {
    let accounts = await Handleconnect();
    // console.log("=====accounts===",accounts)
    if (accounts == "meta_error") {
      handleClose();
    } else {
      if (accounts) {
        dispatch(setwalletConnected(true));
        dispatch(setWalletAddress(accounts));
        setShow(false);
        handleShowConnect();
      }
    }
  };

  function capitalizeFirstLetter(str) {
    console.log("str", str, str.toString());
    str = str.toString();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  async function get_prediction(limit, searchTerm) {
    setLoader(true);

    try {
      let response;
      const cleanedPathname = pathname.startsWith("/") ? pathname.substring(1) : pathname;
      // if (pathname == "/solo-predictions" || pathname == "/solo-predictions/") {
      //   response = await apiService.fetch_solo_prediction(limit,soloStatus,soloTheme,valueFilter);
      // } else if (pathname == "/multi-choice-predictions" || pathname == "/multi-choice-predictions/") {
      //   response = await apiService.fetch_multi_prediction(limit,soloStatus,soloTheme,valueFilter);
      // } else {
      response = await apiService.get_prediction(limit, soloStatus, soloTheme, valueFilter, searchTerm, cleanedPathname);
      // }

      if (response.status == 200) {
        settotalRecords(response.data.data.totalRecords);
        setpredictions(response.data.data.list);
        setLoader(false);

        // Step 2: Check if predictions length is greater than 3
        if (response.data.data.list.length > 3) {
          setShowAd(true); // Set ad to show
        }
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }
  }

  const viewmore = () => {
    setlimit(limit + 6);
    get_prediction(limit + 6, searchTerm);
  };
  const viewless = () => {
    setlimit(9);
    get_prediction(9, searchTerm);
  };

  const openSoloPrediction = () => {
    navigate("/solo-predictions");
    setIsSoloPrediction(true);
    setIsMultiChoicePrediction(false);
    setSoloStatus("Open");
  };

  const openMultiPrediction = () => {
    navigate("/multi-choice-predictions");
    setIsMultiChoicePrediction(true);
    setIsSoloPrediction(false);
    setSoloStatus("Open");
    //
  };
  const rendersolo = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Predict Tokens, Sports & Pop Culture Trends: Win Up To $1,000 Daily 🌟
    </Tooltip>
  );
  const rendermultichoice = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Predict All Options Correctly: Win Up To $5,000 Daily 🔮
    </Tooltip>
  );
  const renderscores = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Predict Game Scores: Win $100’s Daily ⚽️
    </Tooltip>
  );
  const renderpump = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Predict Pump.fun Memecoin performance: Win $FOLIO 💊
    </Tooltip>
  );

  const adData = adConfig["Home"]; // Get specific page ad config
  console.log("adData::::::::", adData);
  // const renderAds = () => {
  //   return adData?.ids?.map((adId, index) => (
  //     <AdComponent key={adId} adId={adId} dimension={adData.dimensions[index]} />
  //   ));
  // };

  // function renderAds(adId, dimensions, className) {
  //   return <AdComponent key={adId} adId={adId} dimension={dimensions} className={className} />
  // }
  // <Tooltip id="tooltip-solo">
  //   <p className="mb-0">Predict Tokens, Sports & Pop Culture Trends: Win Up To $1,000 Daily 🌟</p>
  // </Tooltip>

  function renderAds(adId, dimensions, className) {
    const isMobile = window.innerWidth <= 768; // Define breakpoint for mobile
    const dimension = isMobile ? dimensions.mobile : dimensions.desktop;
    const adKey = isMobile ? adId.mobile : adId.desktop;

    return (
      <AdComponent
        key={adKey}
        adId={adKey}
        dimension={dimension}
        className={className}
      />
    );
  }

  return (
    <>
      {/* {!isSoloPresiction && (
        <p className="banner-top-message" onClick={openSoloPrediction}>
          Solo Challenge: Win Up To $1,000 Daily 💪{" "}
        </p>
      )}
      {!isMultiChoicePresiction && (
        <p
          className="banner-top-message blue-message-top"
          onClick={openMultiPrediction}
        >
          Multi-Choice Challenge: Win Up To $5,000 Daily 🔮{" "}
        </p>
      )} */}

      <section className="banner-area">
        {/* <div className="ads-banner-top">
          {renderAds(adData?.ids[0], adData?.dimensions[0], "ads-banner-top")}
        </div> */}
        {/* <div className="ads-banner-top">
          {renderAds(
            {
              desktop: adData?.ids.desktop[0],
              mobile: adData?.ids.mobile[0],
            },
            {
              desktop: adData?.dimensions.desktop[0],
              mobile: adData?.dimensions.mobile[0],
            },
            "ads-banner-top"
          )}
        </div> */}

        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={9} xl={6}>
              {/* ---FOLIO BANNER AREA --- */}
              <div className="folio-banner" onClick={()=>window.open("https://x.com/betfoliox/status/1894376865520927209?s=46")} style={{cursor:"pointer"}}>
                <img
                  src={require("../assets/images/sfola-banner.png")}
                  alt="banner || Betfolio"
                />
              </div>
              {/* ---FOLIO BANNER AREA --- */}
              <div className="banner-area-content">
                <h1>Play Prediction Games</h1>
                <p>Win Crypto Rewards</p>
                <NotConnectWalletPopup
                  referralCode={referralCode}
                  show={show}
                  handleClose={handleClose}
                  connectWallet={connectWallet}
                />
                <ConnectWalletPopup
                  show={showconnect}
                  handleClose={handleCloseConnect}
                  connectWallet={connectWallet}
                />
                {iswallet ||
                  localStorage.getItem("is_walletConnected") ||
                  localStorage.getItem("connected_wallet") ||
                  localStorage.getItem("user_type") == "CUSTODIAL" ? null : (
                  <div className="btn-height">
                    <Button type="button" variant="unset" onClick={handleShow}>
                      Login
                    </Button>
                  </div>
                )}
                <img
                  src={require("../assets/images/vector.png")}
                  alt="vector"
                />
              </div>
              <SearchComponent />
            </Col>
          </Row>
        </Container>
        {/* <div className="folio-icon" onClick={handleShowFolio}>
          <img src={require("../assets/images/folio-icon.svg").default} alt="img" />
        </div> */}
      </section>

      <section className="common-padding">
        <Container className="spacing-area-section">
          <div className="prediction-market-area">
            <Row className="mb-5" style={{ alignItems: "self-end" }}>
              <Col md={12} lg={5} xl={7}>
                {isSoloPresiction ? (<h2 className="multi-choice-predict">Explore Solo Predictions </h2>) :
                  isMultiChoicePresiction ? (<h2 className="multi-choice-predict">Explore Multi Choice Predictions</h2>) :
                    isScoresPresiction ? (<h2 className="multi-choice-predict">Explore Scores Predictions </h2>) :
                      isPumpFunPresiction ? (<h2 className="multi-choice-predict">Explore Pump.fun Predictions </h2>) :
                        (<h2 className="multi-choice-predict">Explore Predictions </h2>)}
                         {/* --- BADGE AREA --- */}
                <div className="badge-outer mt-3 d-flex flex-wrap gap-2 align-items-center">
                  <span onClick={() => navigate("/")} className="all-badge px-3 py-1 rounded-1 text-light" style={{ cursor: "pointer" }}>All</span>
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={rendersolo}
                    >
                  <span onClick={() => navigate("/solo-predictions")} className="all-badge solo px-3 py-1 rounded-1 text-light" style={{ cursor: "pointer" }}
                    >Solo</span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={rendermultichoice}
                    >
                  <span onClick={() => navigate("/multi-choice-predictions")} className="all-badge mulitchoice px-3 py-1 rounded-1 text-light" style={{ cursor: "pointer" }}>Multichoice</span>
                  </OverlayTrigger>
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderscores}
                    >
                  <span onClick={() => navigate("/scores")} className="all-badge scores px-3 py-1 rounded-1 text-light" style={{ cursor: "pointer" }}>Scores</span>
                  </OverlayTrigger>
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderpump}
                    >
                  <span onClick={() => navigate("/pump-fun")} className="all-badge pump px-3 py-1 rounded-1 text-light" style={{ cursor: "pointer" }}>Pump.fun</span>
                  </OverlayTrigger>
                </div>
            {/* --- BADGE AREA --- */}
              </Col>
              <Col sm={12} md={8} lg={7} xl={5}>
                <div className="explore-top-filter">
                  {/* {isSoloPresiction || isMultiChoicePresiction ? */}
                  <>
                    <Form.Group
                      className="filter-area-top soloprediction-filter"
                      controlId="valuefilter"
                    >
                      <Form.Label>Value:</Form.Label>
                      <Select
                        options={valueFilterOptions}
                        placeholder="All"
                        onChange={(e) => setValueFilter(e.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      className="filter-area-top soloprediction-filter"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Theme:</Form.Label>
                      <Select
                        options={soloThemeOptions}
                        placeholder="All"
                        onChange={(e) => setSoloTheme(e.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      className="filter-area-top"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Status:</Form.Label>
                      <Select
                        options={soloFilterOptions}
                        placeholder="Open"
                        onChange={(e) => setSoloStatus(e.value)}
                      />
                    </Form.Group>
                  </>
                  {/* :
                    <>
                      <Form.Group
                        className="filter-area-top"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Filter By:</Form.Label>
                        <Select
                          options={options}
                          placeholder="All"
                          onChange={(e) => filterduration(e.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="filter-area-top"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Status:</Form.Label>
                        <Select
                          options={optionsFilter}
                          placeholder="All"
                          onChange={(e) => filtertype(e.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="filter-area-top select-prediction"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Type:</Form.Label>
                        <Select
                          options={optionsPredictions}
                          placeholder="All"
                          onChange={(e) => handlePredictionChange(e.value)}
                        />

                      </Form.Group>
                    </>
                  } */}
                </div>
              </Col>
            </Row>
           
            <Row className="justify-content-center">
              {loader && <ThreeDotSpinner />}
              {predictions?.length > 0 ? (
                predictions.map((data, index) => {
                  return (
                    <>
                      <Col md={6} lg={6} xl={4} className="mb-4">
                        <div className="prediction-box-outer">
                          {data?.isPin && (
                            <div className="pin-icon">
                              <i
                                class="fa fa-thumb-tack"
                                aria-hidden="true"
                              ></i>
                            </div>
                          )}
                          <div className="bg-vector">
                            {data.duration == "DAY" && (
                              <img
                                src={require("../assets/images/bg-vector-days.png")}
                                alt="img"
                              />
                            )}
                            {data.duration == "WEEK" && (
                              <img
                                src={require("../assets/images/bg-vector-week.png")}
                                alt="img"
                              />
                            )}
                            {data.duration == "MONTH" && (
                              <img
                                src={require("../assets/images/bg-vector.png")}
                                alt="img"
                              />
                            )}
                            {data.duration == "HOUR" && (
                              <img
                                src={require("../assets/images/bg-vector.png")}
                                alt="img"
                              />
                            )}
                            <p>1 {data.duration.toLowerCase()}</p>
                          </div>
                          {console.log("data.solo_type", data.solo_type)}
                          {data.solo_type && (
                            <SoloTypeTooltip
                              soloType={data.solo_type}
                              amount={formatBalanceWithDecimals(
                                data?.amount,
                                data?.decimal
                              )}
                            />
                          )}
                          {data && data.prediction_category === "QUIZ" ? (
                            <div
                              className={`quiz-listing ${data.prediction_category.toLowerCase()}-listing`}
                            >
                              <p>
                                {data.prediction_category === "QUIZ"
                                  ? "Quiz"
                                  : ""}
                              </p>
                            </div>
                          ) : null}
                          <PredictionBox data={data} />
                        </div>
                      </Col>

                      {/* Second Ad Placement */}
                      {/* {index === 2 && predictions?.length > 3 && (
                          <div className="contraffic_home_add">
                            {renderAds(adData?.ids[2], adData?.dimensions[2], "contraffic_home_add")}
                          </div>
                        )} */}
                      {/* {index === 2 && predictions?.length > 3 && (
                          <div className="contraffic_home_add">
                            {renderAds(
                              {
                                desktop: adData?.ids.desktop[1],
                                mobile: adData?.ids.mobile[1],
                              },
                              {
                                desktop: adData?.dimensions.desktop[1],
                                mobile: adData?.dimensions.mobile[1],
                              },
                              "contraffic_home_add"
                            )}
                          </div>
                        )} */}
                    </>
                  );
                })
              ) : (
                <Col md={6} lg={6} xl={4} className="mb-4">
                  <div className="no-data-area main-no-data">
                    <img
                      src={require("../assets/images/no-data.gif")}
                      alt="img"
                    />
                    <p>More Predictions Coming Soon</p>
                  </div>
                </Col>
              )}
            </Row>
            {totalRecords > 9 && predictions.length > 0 && (
              <>
                {totalRecords <= limit ? (
                  <Button
                    type="button"
                    variant="unset"
                    className="viw-more-btn"
                    onClick={() => viewless()}
                  >
                    {loader ? "Loading..." : "View Less"}
                  </Button>
                ) : (
                  <Button
                    type="button"
                    variant="unset"
                    className="viw-more-btn"
                    onClick={() => viewmore()}
                  >
                    {loader ? "Loading..." : "View More"}
                  </Button>
                )}
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};
export default Home;
