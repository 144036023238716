import axios from "axios";
import { apiUrl } from "../config/config";


export const apiService = {
    login,
    loginWithWeb3,
    get_prediction,
    getMarketplaceNfts,
    getMyNfts,
    getMarketplaceNftDetails,
    getPredictionFilter,
    get_predictionDetail,
    leaderboardDetail,
    checkprediction,
    userTransaction,
    contactUs,
    subscribePrediction,
    createPaypalOrder,
    capturePaypalOrder,
    getUSDMaticPrice,
    userDetailData,
    predictionCheckforWallet,
    leaderBoardPoints,
    leaderBoardPoints30Days,
    checkBet,
    fetch_solo_prediction,
    fetch_multi_prediction,
    referralLeaderBoard,
    convertRedeem,
    getRedmeedPoints,
    getPredictionBetsCount,
    getBonusPoint,
    getRedmeedList,
    saveSolanaAddress,
    getBenjaminNft
}


const headers =() =>
    {
        let token = localStorage.getItem("token")
        return {headers : { 'Authorization': `${token}` }} 
    };

//    console.log('headers()....', headers());






async function login(data) {
    return await axios.post(`${apiUrl}/user/login/`, data);
}

async function loginWithWeb3(data) {
  return await axios.post(`${apiUrl}/user/loginweb3/`, data);
}

async function fetch_multi_prediction(limit,status,theme, valueType){
  return await axios.get(`${apiUrl}/user/getMultiChoicePredictions?limit=`+limit+`&type=`+status+`&theme=`+theme+`&solo_type=`+valueType , headers())
}

async function fetch_solo_prediction(limit,status,theme, valueType){
  return await axios.get(`${apiUrl}/user/getSoloPredictions?limit=`+limit+`&type=`+status+`&theme=`+theme+`&solo_type=`+valueType , headers())
}
async function get_prediction(limit,status,theme, valueType,searchTerm,providerType="All"){
  return await axios.get(`${apiUrl}/user/predictionList?limit=${limit}&type=${status}&theme=${theme}&solo_type=${valueType}&q=${searchTerm}&provider_type=${providerType}`, headers())
}
async function getMarketplaceNfts(page,status,limit=12){
  return await axios.get(`${apiUrl}/user/marketplace?page=${page}&limit=${limit}&filter=${status}`, headers())
}
async function getMyNfts(page,limit=12,filter){
  return await axios.get(`${apiUrl}/user/my-nft?page=${page}&limit=${limit}&filter=${filter}`, headers())
}
async function getMarketplaceNftDetails(tokenId){
  return await axios.get(`${apiUrl}/user/nft/${tokenId}`, headers())
}
async function get_predictionDetail(address){
  return await axios.get(`${apiUrl}/user/predictionDetail/`+address, headers())
}
async function leaderboardDetail(address,limit){
  return await axios.get(`${apiUrl}/user/leaderboardDetail/`+address+'?limit='+limit, headers())
}

async function getRedmeedPoints(){
  return await axios.get(`${apiUrl}/user/user-points`, headers())
}

async function getPredictionBetsCount(contract){
  return await axios.get(`${apiUrl}/user/get-prediction-bets/${contract}`, headers())
}

async function getBonusPoint(){
  return await axios.get(`${apiUrl}/user/get-bonus-point`, headers())
}

async function getRedmeedList(status,page){
  return await axios.get(`${apiUrl}/user/redeem-points-history?page=${page}&history=${status}`, headers())
}

async function getPredictionFilter(search){
  return await axios.get(`${apiUrl}/user/searchPredictions/${search}`, headers())
}
async function checkprediction(address,wallet_address){
  return await axios.get(`${apiUrl}/user/user-prediction/`+address+"/"+wallet_address)
}


async function userTransaction(page){
  return await axios.get(`${apiUrl}/user/user-transaction?page=`+page,headers())
}

async function userDetailData(){
  return await axios.get(`${apiUrl}/user/userDetail`,headers())
}

async function subscribePrediction(data){
  return await axios.post(`${apiUrl}/user/subscribePrediction`,data,headers())
}

async function createPaypalOrder(){
  return await axios.get(`${apiUrl}/user/paypal/createOrder`,headers())
}

async function capturePaypalOrder(params){
  return await axios.post(`${apiUrl}/user/paypal/captureOrder`,params,headers())
}

async function getUSDMaticPrice(){
  return await axios.get(`${apiUrl}/user/get-usd-matic-price`,headers())
}

async function contactUs(data){
  return await axios.post(`${apiUrl}/user/contact-us`,data,headers())
}
async function convertRedeem(data){
  return await axios.post(`${apiUrl}/user/redeem-points`,data,headers())
}
async function predictionCheckforWallet(contractAddress,walletAddr){
  return await axios.get(`${apiUrl}/user/prediction-detail/${contractAddress}/${walletAddr}`,headers())
}

async function leaderBoardPoints(page){
  return await axios.get(`${apiUrl}/user/leaderBoard-points?page=`+page, headers())
}

async function leaderBoardPoints30Days(page){
  return await axios.get(`${apiUrl}/user/pointsLeaderBoardLast30Days?limit=50&page=`+page, headers())
}

async function referralLeaderBoard(leaderBoradType,page){
  return await axios.get(`${apiUrl}/user/referelLeaderBoard?type=`+leaderBoradType+"&page="+page, headers())
}

async function checkBet(data){
  return await axios.post(`${apiUrl}/user/placeBet`,data,headers())
}

async function saveSolanaAddress(data) {
  console.log("data>>>>>>",data);
  return await axios.put(`${apiUrl}/user/userUpdate`,data,headers())
}

async function getBenjaminNft(){
  return await axios.get(`${apiUrl}/user/get-benjamin-nft`, headers())
}
