import React from "react";
import { Modal } from "react-bootstrap";

const ApprovalModal = ({ show, onClose, tokenApproved, minting, isLoading }) => {
  return (
    <Modal show={show} backdrop="static" onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Follow Steps</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {/* Step 1: Approving USDC */}
        <div className="step-area">
          <div className="steps-left-area">
            {!tokenApproved && isLoading ? <div className="loader"></div> : <h2>✓</h2>}
          </div>
          <div className="steps-content">
            <h6>Approve USDC</h6>
            <p>{tokenApproved ? "USDC Approved" : "Approving USDC..."}</p>
          </div>
        </div>

        {/* Step 2: Minting NFT */}
        <div className="step-area">
          <div className="steps-left-area">
            {tokenApproved && minting ? <div className="loader"></div> : <h2>✓</h2>}
          </div>
          <div className="steps-content">
            <h6>Mint NFT</h6>
            <p>Sending transaction to mint NFT...</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApprovalModal;
