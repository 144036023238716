import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const Promptbuilder = () => {
  const predictionOptions = [
    "Which 3 coins will have the highest 1hr return?",
    "Which 3 coins will have the highest 1hr return?(v2)",
    "Which 3 coins will have the highest 1hr return?(v3)",
    "Which 3 coins will have the highest 1hr return?(v4)",
    "Which 3 coins will have the highest 1hr return?(v9)",
    "Which 3 coins will have the highest 1hr return?(v5)",
    "Which coin will have the highest 1hr return?",
    "Which Solana token will have the highest 1hr return?",
    "Which L2 token will have the highest 1hr return?",
    "Which gaming token will have the highest 1hr return?",
    "Which meme coin will have the highest 1hr return?",
    "Which AI coin will have the highest 1hr return?",
    "Which DeFi token will have the highest 1hr return?",
    "Which Cosmos token will have the highest 1hr return?",
    "Which Liquid Staking token will have the highest 1hr return?",
    "Which SocialFi token will have the highest 1hr return?",
    "Which stock will have the highest 1hr return?",
    "Which stock will have the highest 1hr return?(v2)",
    "Which stock will have the highest 1hr return?(v4)",
    "Which stock will have the highest 1hr return?(v5)",
    "Which stock will have the highest 1hr return?(v6)",
    "Which stock will have the highest 1hr return?(v7)",
    "Which stock will have the highest 1hr return?(v8)",
    "Which stock will have the highest 1hr return?(v9)",
    "Which stock will have the highest 1hr return?(v10)",
    "Which stock will have the highest 1hr return?(v11)",
    "Which stock will have the highest 1hr return?(v12)",
    "Which stock will have the highest 1hr return?(v13)",
    "Which stock will have the highest 1hr return?(v14)",
    "Which stock will have the highest 1hr return?(v15)",
    "Which stock will have the highest 1hr return?(v16)",
    "Which stock will have the highest 1hr return?(v17)",
    "Which stock will have the highest 1hr return?(v18)",
    "Which stock will have the highest 1hr return?(v19)",
    "Which stock will have the highest 1hr return?(v20)",
    "Which stock will have the highest 1hr return?(v21)",
    "Which stock will have the highest 1hr return?(v22)",
    "Which stock will have the highest 1hr return?(v23)",
    "Which stock will have the highest 1hr return?(v24)",
    "Which stock will have the highest 1hr return?(v25)",
    "Which 3 stocks will have the highest 1hr return?",
    "Which 3 stocks will have the highest 1hr return?(v2)",
    "Which 3 stocks will have the highest 1hr return?(v3)",
    "Which 3 coins will have the highest 1hr return?(v6)",
    "Which 3 coins will have the highest 1hr return?(v7)",
    "Which 3 coins will have the highest 1hr return?(v8)",
  ];

  const dailyPredictionResults = [
    "What is the most common result for the prediction:",
    "How many times does [Result X] appear as a result for the statement: ",
  ];

  const memecoinIndexReturnData = [
    "Give me a token with minimum $300k market cap that has the lowest volatility but a steady uptrend. Exclude tokens with data containing missing or zero values.",
    "Which token with minimum $300k market cap has the highest increase in 1-hr return compared to their 24-hour return?",
    "Which token with minimum $300k market cap has consistently positive return over the last few 1-hour intervals but is not in the top 10 highest average performance?",
    "Which mid-tiers (based on average return) with minimum $300k market cap have seen the largest increase in trading volume in the last 3 days?",
    "Which token with minimum $300k market cap has increasing 1-hour volume but relatively low price movement?",
    "Which token has the highest Sharpe ratio (return relative to volatility) in the past 24 hours but is in the top 10 best performers?",
  ];

  const trendingPumpFunTokenData = [
    "Which token has the most consistent ranking in the Top 20 over time?",
    "Which token has shown the strongest long-term upward trend in ranking?",
    "Which token has the highest historical returns over multiple timeframes (1hr, 24hr)?",
    "Which token has the highest transaction count over time?",
    "Which token has the highest volume and market cap growth?",
    "Which token has the lowest volatility in ranking position?",
    "Which token has maintained a high ranking for the longest period?",
  ];

  const footballMatchFinalScoresData = [
    {
      title: "General Tends & Patterns",
      questions: [
        "What is the average number of goals scored per match by each team in the dataset?",
        "What are the most common final scores for matches involving a specific team?",
        "How often do matches end in specific scorelines (e.g., 1-0, 2-1, 3-2, etc.)?",
        "Which teams have the highest and lowest goal-scoring averages in home and away matches?",
        "How does the number of goals scored vary by league or competition?",
      ],
    },
    {
      title: "Team-Specific Performance Analysis",
      questions: [
        "How does (Team X) perform against (Team Y) historically in terms of goals and final scores?",
        "What is (Team X)’s goal-scoring and conceding average in their last 10 matches?",
        "How often does (Team X) keep a clean sheet or concede goals in their matches?",
        "What is the probability of (Team X) scoring over/under 2.5 goals in a match?",
        "How does (Team X) perform when playing at home vs. away in terms of goals scored and conceded?",
      ],
    },
    {
      title: "Recent Forms & Streaks",
      questions: [
        "Which teams are on a high-scoring streak or a low-scoring streak?",
        "Are there teams that consistently win or lose by a particular scoreline (e.g., 2-0, 1-1)?",
        "What is the longest streak of matches where (Team X) has scored/conceded goals?",
        "Are there specific months or periods in the season where certain teams tend to score more or fewer goals?",
      ],
    },
    {
      title: "Betting-Oriented Questions",
      questions: [
        "What is the likelihood of both teams scoring (BTTS) in matches involving (Team X)?",
        "What is the probability of a match involving (Team X) having over/under 2.5 total goals?",
        "Which teams are the most reliable for correct score bets (e.g., teams that frequently end matches with a predictable final score)?",
        "How often do (Team X)’s matches end in draws, home wins, or away wins with specific scorelines?",
        "What are the best scoreline predictions based on past performances for an upcoming match between (Team X) and (Team Y)?",
        "What is the expected number of goals in a match between (Team X) and (Team Y) based on historical head-to-head results and current form?",
      ],
    },
    {
      title: "Advanced Statistical Insights",
      questions: [
        "Which teams are most affected by home advantage in terms of goals scored and conceded?",
        "Are there particular scorelines that are more common when (Team X) plays against a stronger/weaker opponent?",
      ],
    },
  ];

  const predictionOptionsfour = [
    "Which coin will have the highest 24hr return?",
    "Which coin will have the highest 24hr return?(v2)",
  "Which coin will have the highest 24hr return?(v3)",
  "Which coin will have the highest 24hr return?(v4)",
  "Which coin will have the highest 24hr return?(v5)",
  "Which coin will have the highest 24hr return?(v6)",
  "Which coin will have the highest 24hr return?(v7)",
  "Which coin will have the highest 24hr return?(v8)",
  "Which coin will have the highest 24hr return?(v9)",
  "Which coin will have the highest 24hr return?(v10)",
  "Which coin will have the highest 24hr return?(v11)",
  "Which coin will have the highest 24hr return?(v12)",
  "Which coin will have the highest 24hr return?(v13)",
  "Which coin will have the highest 24hr return?(v14)",
  "Which coin will have the highest 24hr return?(v15)",
  "Which coin will have the highest 24hr return?(v16)",
  "Which coin will have the highest 24hr return?(v17)",
  "Which coin will have the highest 24hr return?(v18)",
  "Which coin will have the highest 24hr return?(v19)",
  "Which coin will have the highest 24hr return?*",
  "Which Solana token will have the highest 24hr return?",
  "Which L2 token will have the highest 24hr return?",
  "Which gaming token will have the highest 24hr return?",
  "Which meme coin will have the highest 24hr return?",
  "Which AI coin will have the highest 24hr return?",
  "Which DeFi token will have the highest 24hr return?",
  "Which Cosmos token will have the highest 24hr return?",
  "Which Liquid Staking token will have the highest 24hrreturn?",
  "Which stablecoin will have the highest 24hr % increasein market cap?",
  "Which SocialFi token will have the highest 24hr return?",
  "How much will the price of SOL move in 24hrs?",
  "How much will the price of BTC move in 24hrs?",
  "How much will the price of ETH move in 24hrs?",
  "How much will the price of BNB move in 24hrs?",
  "How much will the price of AVAX move in 24hrs?",
  "Which chain will have the highest 24hr percentage increase in TVL?",
  "Which chain will have the highest 24hr percentage increase in TVL?(v2)",
  "Which chain will have the highest 24hr percentage increase in TVL?(v3)",
  "Which protocol will have the highest 24hr % increase inTVL?",
  "Which DEX will have the highest 24hr % increase in TVL?",
  "Which Liquid Staking Protocol will have the highest 24hr% increase in TVL?",
  "Which Lending Protocol will have the highest 24hr %increase in TVL?",
  "Which RWA Protocol will have the highest 24hr % increasein TVL?",
  "Which Derivatives Protocol will have the highest 24hr %increase in TVL?",
  "Which Bridge will have the highest 24hr % increase inTVL?",
  "Which Solana dApp will have the highest 24hr % increasein TVL?",
  "Which payments dApp will have the highest 24hr %increase in TVL?",
  "Which SocialFi dApp will have the highest 24hr %increase in TVL?",
  "Which NFT marketplace will have the highest 24hr %increase in TVL?",
  "Which celebrity will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which brand will have the highest increase in LunarCrushtopic rank in 24hrs?",
  "Which game will have the highest increase in LunarCrushtopic rank in 24hrs?",
  "Which football team will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which football team will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which artist will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which artist will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which artist will have the highest increase inLunarCrush topic rank in 24hrs?(v3)",
  "Which NBA team will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which NBA team will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which NBA team will have the highest increase inLunarCrush topic rank in 24hrs?(v3)",
  "Which coin will have the highest 24hr percentage increase in open interest?",
  "Which Bitcoin ETF will have the highest 24hr % increasein BTC holdings?",
  "Which chain will have the highest 24hr % increase inDAUs?",
  "Which Polygon dapp will have the highest 24hr % increasein UAW?",
  "Which dapp will have the highest 24hr % increase in UAW?",
  "Which dapp will have the highest 24hr % increase inUAW?(v2)",
  "Which dapp will have the highest 24hr % increase inUAW?(v3)",
  "Which Solana dapp will have the highest 24hr % increasein UAW?",
  "Which Solana dapp will have the highest 24hr % increasein UAW?(v2)",
  "Which chain will have the highest 24hr % increase involume?",
  "Which chain will have the highest 24hr % increase infees?",
  "Which CT influencer will gain the most new followers in24hrs?",
  "Which L1 coin will have the highest 24hr return?",
  "Which L1 coin will have the highest 24hr return?(v2)",
  "How much will the price of TON move in 24hrs?",
  "How much will the price of APT move in 24hrs?",
  "How much will the price of NEAR move in 24hrs?",
  "How much will the price of SUI move in 24hrs?",
  "How much will the price of WIF move in 24hrs?",
  "How much will the price of TAO move in 24hrs?",
  "How much will the price of BONK move in 24hrs?",
  "How much will the price of JUP move in 24hrs?",
  "Which DePIN token will have the highest 24hr return?",
  "How much will the price of PEPE move in 24hrs?",
  "Which Gaming dapp will have the highest 24hr % increasein UAW?",
  "Which DeFi dapp will have the highest 24hr % increase inUAW?",
  "Which DeFi dapp will have the highest 24hr % increase inUAW?(v2)",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?(v2)",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?(v3)",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?(v4)",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?(v5)",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?(v6)",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?(v7)",
  "Which topic will have the highest increase in LunarCrushrank in 24hrs?(v8)",
  "Which brand will have the highest increase in LunarCrushtopic rank in 24hrs?(v2)",
  "Which brand will have the highest increase in LunarCrushtopic rank in 24hrs?(v3)",
  "Which luxury brand will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which luxury brand will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which luxury brand will have the highest increase inLunarCrush topic rank in 24hrs?(v3)",
  "Which athlete will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which sports league will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which sports league will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which celebrity will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which celebrity will have the highest increase inLunarCrush topic rank in 24hrs?(v3)",
  "Which celebrity will have the highest increase inLunarCrush topic rank in 24hrs?(v4)",
  "Which crypto influencer will gain the most new followersin 24hrs?(v2)",
  "Which crypto influencer will gain the most new followersin 24hrs?(v3)",
  "Which game will have the highest increase in LunarCrushtopic rank in 24hrs?(v2)",
  "Which game will have the highest increase in LunarCrushtopic rank in 24hrs?(v3)",
  "Which game will have the highest increase in LunarCrushtopic rank in 24hrs?(v4)",
  "Which game will have the highest increase in LunarCrushtopic rank in 24hrs?(v5)",
  "Which city will have the highest increase in LunarCrushtopic rank in 24hrs?",
  "Which city will have the highest increase in LunarCrushtopic rank in 24hrs?(v2)",
  "Which city will have the highest increase in LunarCrushtopic rank in 24hrs?(v3)",
  "Which country will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which country will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which F1 driver will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which golfer will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which UFC fighter will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which dapp will have the highest 24hr % increase inUAW?(v4)",
  "Which protocol will have the highest 24hr % increase inTVL?(v2)",
  "Which protocol will have the highest 24hr % increase inTVL?(v3)",
  "Which protocol will have the highest 24hr % increase inTVL?(v4)",
  "Which protocol will have the highest 24hr % increase inTVL?(v5)",
  "Which protocol will have the highest 24hr % increase inTVL?(v6)",
  "Which protocol will have the highest 24hr % increase inTVL?(v7)",
  "Which protocol will have the highest 24hr % increase inTVL?(v8)",
  "Which celebrity will have the highest increase inLunarCrush topic rank in 24hrs?(v5)",
  "Which artist will have the highest increase inLunarCrush topic rank in 24hrs?(v4)",
  "Which country will have the highest increase inLunarCrush topic rank in 24hrs?(v3)",
  "Which country will have the highest increase inLunarCrush topic rank in 24hrs?(v4)",
  "Which celebrity will have the highest increase inLunarCrush topic rank in 24hrs?(v6)",
  "Which Solana token will have the highest 24hr return?*",
  "Which L2 token will have the highest 24hr return?*",
  "Which gaming token will have the highest 24hr return?*",
  "Which meme coin will have the highest 24hr return?*",
  "Which AI coin will have the highest 24hr return?*",
  "Which DeFi token will have the highest 24hr return?*",
  "Which Cosmos token will have the highest 24hr return?*",
  "Which Liquid Staking token will have the highest 24hrreturn?*",
  "Which stablecoin will have the highest 24hr % increasein market cap?*",
  "Which SocialFi token will have the highest 24hr return?*",
  "How much will the price of SOL move in 24hrs?*",
  "How much will the price of BTC move in 24hrs?*",
  "How much will the price of ETH move in 24hrs?*",
  "Which 3 coins will have the highest 24hr return in orderof performance?",
  "Which 3 coins will have the highest 24hr return in orderof performance?(v2)",
  "Which 4 coins will have the highest 24hr return in orderof performance?",
  "Which 3 countries will have the highest 24hr increase inLunarCrush topic rank?",
  "Which 3 celebs will have the highest 24hr increase inLunarCrush topic rank?",
  "Which 3 protocols will have the highest 24hr % increasein TVL?",
  "Which coin will have the highest 24 hour return?",
  "Which coin will have the highest 24 hour return?(v2)",
  "Which coin will have the highest 24 hour return?(v3)",
  "Which coin will have the highest 24 hour return?(v4)",
  "Which coin will have the highest 24 hour return?(v5)",
  "Which coin will have the highest 24 hour return?(v6)",
  "Which coin will have the highest 24 hour return?(v7)",
  "Which coin will have the highest 24 hour return?(v8)",
  "Which coin will have the highest 24 hour return?(v9)",
  "Which coin will have the highest 24 hour return?(v10)",
  "Which artist will have the highest increase in SpotifyStreams in 24hrs?",
  "Which artist will have the highest increase in SpotifyStreams in 24hrs?(v2)",
  "Which artist will have the highest increase in SpotifyStreams in 24hrs?(v3)",
  "Which politician will have the highest increase inLunarCrush topic rank in 24hrs?",
  "Which athlete will have the highest increase inLunarCrush topic rank in 24hrs?(v2)",
  "Which Lending Protocol will have the highest 24hr %increase in TVL?(v2)",
  "Which DEX will have the highest 24hr % increase inTVL?(v2)",
  "Which chain will have the highest 24hr percentage increase in TVL?(v4)",
  "Which CEX will have the highest percentage increase in24hr spot volumes?",
  "Which stock will have the highest 24hr return?",
  "Which stock will have the highest 24hr return?(v2)",
  "Which stock will have the highest 24hr return?(v3)",
  "Which stock will have the highest 24hr return?(v4)",
  "Which stock will have the highest 24hr return?(v5)",
  "Which stock will have the highest 24hr return?(v6)",
  "Which stock will have the highest 24hr return?(v7)",
  "Which stock will have the highest 24hr return?(v8)",
  "Which stock will have the highest 24hr return?(v9)",
  "Which stock will have the highest 24hr return?(v10)",
  "Which stock will have the highest 24hr return?(v11)",
  "Which stock will have the highest 24hr return?(v12)",
  "Which stock will have the highest 24hr return?(v13)",
  "Which stock will have the highest 24hr return?(v14)",
  "Which stock will have the highest 24hr return?(v15)",
  "Which stock will have the highest 24hr return?(v16)",
  "Which stock will have the highest 24hr return?(v17)",
  "Which stock will have the highest 24hr return?(v18)",
  "Which stock will have the highest 24hr return?(v19)",
  "Which stock will have the highest 24hr return?(v20)",
  "Which stock will have the highest 24hr return?(v21)",
  "Which stock will have the highest 24hr return?(v22)",
  "How much will the price of TSLA move in 24hrs?",
  "How much will the price of NVDA move in 24hrs?",
  "How much will the price of NFLX move in 24hrs?",
  "How much will the price of UBER move in 24hrs?",
  "How much will the price of META move in 24hrs?",
  "How much will the price of COIN move in 24hrs?",
  "How much will the price of AAPL move in 24hrs?",
  "How much will the price of MSFT move in 24hrs?",
  "How much will the price of GOOGL move in 24hrs?",
  "How much will the price of NKE move in 24hrs?",
  ];

  const handleCopy = (text, id) => {
    navigator.clipboard.writeText(text);
    if (id) {
      document.getElementById(id).innerHTML = "copied!";
      setTimeout(() => {
        document.getElementById(id).innerHTML = "copy";
      }, 2000);
    }
  };

  const [dropDownOne, setDropDownOne] = useState(predictionOptions[0]);
  const [dropDownTwo, setDropDownTwo] = useState(
    "Which Solana token will have the highest 1h return?"
  );
  const [dropDownThree, setDropDownThree] = useState(
    "Which Solana token will have the highest 1h return?"
  );
  const [dropDownFour, setDropDownFour] = useState(
    predictionOptionsfour[0]
  );

  const [dropDownFive, setDropDownFive] = useState(predictionOptionsfour[0])

  return (
    <section className="promptbuilder">
      <Container fluid>
        <h1>Generate insightful questions for Benjamin AI</h1>
        {/* ----Hourly Prediction---- */}
        <div className="hourly">
          <h5>Benjamin - Hourly Prediction Results</h5>
          <div className="question-row d-flex">
            <div className="question-col">
              <p>What is the common result for the prediction:</p>
            </div>
            <div className="select-col">
              <Form.Select
                value={dropDownOne}
                onChange={(e) => setDropDownOne(e.target.value)}
                aria-label=""
              >
                {predictionOptions.map((option, index) => {
                  return (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className="copy-btn-col">
              <button
                onClick={() =>
                  handleCopy(
                    `What is the common result for the prediction: ${dropDownOne}`,
                    "copy-1"
                  )
                }
                id="copy-1"
                className="text-white"
              >
                copy
              </button>
            </div>
          </div>

          <div className="question-row d-flex">
            <div className="question-col">
              <p>What is the 2nd most common result for the prediction:</p>
            </div>
            <div className="select-col">
              <Form.Select
                value={dropDownTwo}
                onChange={(e) => setDropDownTwo(e.target.value)}
                aria-label=""
              >
                <option
                  value={"Which Solana token will have the highest 1h return?"}
                >
                  Which Solana token will have the highest 1h return?
                </option>
                {predictionOptions.map((option, index) => {
                  return (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className="copy-btn-col">
              <button
                onClick={() =>
                  handleCopy(
                    `What is the 2nd most common result for the prediction: ${dropDownTwo}`,
                    "copy-2"
                  )
                }
                id="copy-2"
                className="text-white"
              >
                copy
              </button>
            </div>
          </div>

          {/*-----Label Row-----*/}
          <div className="statement-label">
            <h6>Search how many times a result appears in a prediction</h6>
            <div className="question-row d-flex">
              <div className="question-col">
                <p>
                  How many times does (Result X) appears as a result for the
                  statement:
                </p>
              </div>
              <div className="select-col">
                <Form.Select
                  value={dropDownThree}
                  onChange={(e) => setDropDownThree(e.target.value)}
                  aria-label=""
                >
                  <option
                    value={
                      "Which Solana token will have the highest 1h return?"
                    }
                  >
                    Which Solana token will have the highest 1h return?
                  </option>
                  {predictionOptions.map((option, index) => {
                    return (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
              <div className="copy-btn-col">
                <button
                  onClick={() =>
                    handleCopy(
                      `How many times does (Result X) appears as a result for the statement: ${dropDownThree}`,
                      "copy-3"
                    )
                  }
                  id="copy-3"
                  className="text-white"
                >
                  copy
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ---Daily Prediction--- */}
        <div className="hourly">
          <h5>Benjamin - Daily Prediction Results</h5>
          <>
            {/* {dailyPredictionResults.map((item, index) => {
              return (
                <div key={index} className="question-row d-flex">
                  <div className="question-col">
                    <p>{item}</p>
                  </div>
                  <div className="select-col">
                    <Form.Select value={dropDownFour} onChange={(e)=>setDropDownFour(e.target.value)} aria-label="">
                  {
                  predictionOptionsfour.map((option,index)=>{
                    return (
                      <option key={index} value={option}>{option}</option>
                    )
                  })
                }
                </Form.Select>
                  </div>
                  <div className="copy-btn-col">
                    <button
                      onClick={() => {
                        handleCopy(`${item} ${dropDownFour}`, `copy-btn-${index}`);
                      }}
                      id={`copy-btn-${index}`}
                      className="text-white"
                    >
                      copy
                    </button>
                  </div>
                </div>
              );
            })} */}



                <div className="question-row d-flex">
                  <div className="question-col">
                    <p>What is the most common result for the prediction:</p>
                  </div>
                  <div className="select-col">
                    <Form.Select value={dropDownFour} onChange={(e)=>setDropDownFour(e.target.value)} aria-label="">
                  {
                  predictionOptionsfour.map((option,index)=>{
                    return (
                      <option key={index} value={option}>{option}</option>
                    )
                  })
                }
                </Form.Select>
                  </div>
                  <div className="copy-btn-col">
                    <button
                      onClick={() => {
                        handleCopy(`What is the most common result for the prediction: ${dropDownFour}`, `copy-btn-dpr-1`);
                      }}
                      id={`copy-btn-dpr-1`}
                      className="text-white"
                    >
                      copy
                    </button>
                  </div>
                </div>


                <div className="question-row d-flex">
                  <div className="question-col">
                    <p>How many times does [Result X] appear as a result for the statement:</p>
                  </div>
                  <div className="select-col">
                    <Form.Select value={dropDownFive} onChange={(e)=>setDropDownFive(e.target.value)} aria-label="">
                  {
                  predictionOptionsfour.map((option,index)=>{
                    return (
                      <option key={index} value={option}>{option}</option>
                    )
                  })
                }
                </Form.Select>
                  </div>
                  <div className="copy-btn-col">
                    <button
                      onClick={() => {
                        handleCopy(`How many times does [Result X] appear as a result for the statement: ${dropDownFive}`, `copy-btn-dpr-2`);
                      }}
                      id={`copy-btn-dpr-2`}
                      className="text-white"
                    >
                      copy
                    </button>
                  </div>
                </div>


          </>
        </div>

        {/* ---Memecoin index--- */}

        <div className="hourly memecoin">
          <h5>Benjamin - Memecoin Index Return Data</h5>
          <div className="statement-label">
            <h6>Discover Hidden Germs Before They Breakout</h6>
            {memecoinIndexReturnData.map((item, index) => {
              return (
                <div key={index} className="question-row d-flex">
                  <div className="question-col">
                    <p>{item}</p>
                  </div>
                  <div className="copy-btn-col">
                    <button
                      onClick={() => {
                        handleCopy(item, `copy-btn-list-${index}`);
                      }}
                      id={`copy-btn-list-${index}`}
                      className="text-white"
                    >
                      copy
                    </button>
                  </div>
                </div>
              );
            })}
            <h6>Discover outperformers based on most recent returns</h6>
            <div className="question-row d-flex">
              <div className="question-col">
                <p>
                  Using recent returns, volume trends, and market cap ,
                  determine the top 3 potential outperfoming coins for the next
                  24 hours.
                </p>
              </div>
              <div className="copy-btn-col">
                <button
                  onClick={() => {
                    handleCopy(
                      "Using recent returns, volume trends, and market cap , determine the top 3 potential outperfoming coins for the next 24 hours.",
                      "copy-btn"
                    );
                  }}
                  id="copy-btn"
                  className="text-white"
                >
                  copy
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* --- Trending Pump. fun Token Data--- */}
        <div className="hourly memecoin">
          <h5>Benjamin - Trending Pump. fun Token Data</h5>
          <div className="statement-label">
            <h6>Discover Frequently Trending Tokens</h6>
            {trendingPumpFunTokenData.map((item, index) => {
              return (
                <div key={index} className="question-row d-flex">
                  <div className="question-col">
                    <p>{item}</p>
                  </div>
                  <div className="copy-btn-col">
                    <button
                      onClick={() => {
                        handleCopy(item, `copy-button-${index}`);
                      }}
                      id={`copy-button-${index}`}
                      className="text-white"
                    >
                      copy
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* --- Football Match Final Scores Data--- */}
        <div className="hourly memecoin">
          <h5>Benjamin - Football Match Final Scores Data</h5>
          <div className="statement-label">
            {footballMatchFinalScoresData.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <h6>{item.title}</h6>
                  {item.questions.map((question, subindex) => {
                    return (
                      <div key={subindex} className="question-row d-flex">
                        <div className="question-col">
                          <p>{question}</p>
                        </div>
                        <div className="copy-btn-col">
                          <button
                            onClick={() => {
                              handleCopy(
                                question,
                                `copy-button-list-${subindex}${index}`
                              );
                            }}
                            id={`copy-button-list-${subindex}${index}`}
                            className="text-white"
                          >
                            copy
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Promptbuilder;
